import * as React from "react"
import { Link } from 'gatsby'
import { REVIEW_ROOT } from 'tuna/utils/routes'

interface ReviewListPresentationProps {
  reviews: any[]
}

export const ReviewListPresentation: React.FC<ReviewListPresentationProps> = props => {
  const { reviews } = props
  return (
    <ul>
      {reviews.map(review => (
        <Link key={review.node.id} to={`${REVIEW_ROOT}/${review.node.frontmatter.url}`}>
          {review.node.frontmatter.title}
        </Link>
      ))}
    </ul>
  )
}
