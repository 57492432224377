import * as React from 'react'
import { ReviewList } from 'tuna/components/review-list'
import { Page } from 'tuna/components/page'

export default () => (
  <Page>
    <p>yo</p>
    <ReviewList />
  </Page>
)
