import * as React from "react"
import { graphql, StaticQuery } from 'gatsby'
import { ReviewListPresentation } from './component'

interface GQLQueryData {
  reviews: {
    edges: any[]
  }
}

const ReviewListQuery = graphql`
  query ReviewListQuery {
    reviews: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { collection: { eq: "review" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            title
            url
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`

interface ReviewListProps {
  limit?: number
}

export const ReviewList: React.FC<ReviewListProps> = () => (
  <StaticQuery
    query={ReviewListQuery}
    render={(data: GQLQueryData) => <ReviewListPresentation reviews={data.reviews.edges} />}
  />
)